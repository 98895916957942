/* eslint react/prop-types: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { AppContext } from '../context';
import {
  decodeRedirect, getQuery, getHash, parseParams,
} from '../utils/url';
import MHubRedirect from '../containers/Redirect';

const RouteAuthenticated = ({ component: C, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <AppContext.Consumer>
        {({ state }) => {
          const { location } = props;
          const query = getQuery(location);
          const { redirect, ...queryRest } = query;
          const hash = getHash(location);
          if (state.isAuthenticated) {
            if (redirect) {
              const url = decodeRedirect(redirect);
              if (url.startsWith('http')) {
                return <MHubRedirect to={url} />;
              }
              return <Redirect to={parseParams(url, queryRest, hash)} />;
            }
            return <C {...props} />;
          }
          return <Redirect to={parseParams('/login', query, hash)} />;
        }}
      </AppContext.Consumer>
    )}
  />
);

RouteAuthenticated.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
};

export default RouteAuthenticated;
