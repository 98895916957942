export default {
  app: {
    env: process.env.NODE_ENV,
    version: process.env.REACT_APP_VERSION,
    gaTrackingID: 'UA-72771286-11',
    bgClass: process.env.REACT_APP_BG_CLASS,
    bgCount: process.env.REACT_APP_BG_COUNT,
  },
  cognito: {
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    REGION: process.env.REACT_APP_COGNITO_REGION,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  },
  cookie: {
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  },
  endpoints: {
    loginURL: process.env.REACT_APP_LOGIN_URL,
    apiURL: process.env.REACT_APP_API_URL,
    adminURL: process.env.REACT_APP_ADMIN_URL,
    siteplanURL: process.env.REACT_APP_SITEPLAN_URL,
    developerURL: process.env.REACT_APP_DEVELOPER_URL,
    bankURL: process.env.REACT_APP_BANK_URL,
    lawyerURL: process.env.REACT_APP_LAWYER_URL,
    marketplaceURL: process.env.REACT_APP_MARKETPLACE_URL,
    notificationURL: process.env.REACT_APP_NOTIFICATION_URL,
  },
  gateways: {
    lawyer: {
      endpoint: process.env.REACT_APP_LAWYER_API_ENDPOINT,
      region: process.env.REACT_APP_LAWYER_API_REGION,
      token: process.env.REACT_APP_LAWYER_API_TOKEN,
    },
  },
  bugsnag: {
    RELEASE_STAGE: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE,
  },
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
  },
};
