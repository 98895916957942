/* eslint-disable react/destructuring-assignment */
import React, { PureComponent, createContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Immutable from 'seamless-immutable';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { withSnackBar, utils } from '@mhub/web-components';
import UAParser from 'ua-parser-js';

import {
  getHash, getQuery, decodeRedirect, eraseCookieFromAllPaths, parseParams,
} from '../utils/url';
import { setUser } from '../utils/bugsnag';

import config from '../config';
import {
  LAWYER,
  ADMIN_BASIC,
  MARKETPLACE_AGENT,
  DEVELOPER_SITEPLAN,
  DEVELOPER_EXECUTIVE,
  MORTGAGE_BANKER,
  USER_ADMIN_GROUPS,
  USER_BANKER_GROUPS,
  USER_DEVELOPER_GROUPS,
} from '../constants/groups';
import { fetchM } from '../utils/fetch';
import { SHOWROOM } from '../constants/apps';

const helplineMsg = 'Please email support@mhub.my or contact our community support number 019-6756118 if you need further assistance. Thank you';

const noAccErrMsg = 'Oops! We are unable to find an MHub account linked with your login details. For assistance, please email support@mhub.my or call MHub Helpline: 019-6756118';

const initialState = Immutable({
  isAuthenticated: false,
  loading: false,
  error: null,
  isError: false,
  email: null,
  pinMedium: null,
  pinSentTo: null,
  resetRequired: false,
  pinRequestAt: null,
  loginPassword: null,
  userGroups: [],
  verifyRequired: null,
  isADFS: false,
  adfsProvider: '',
});

/**
 * Generate device from CognitoDevice
 * @param {Device} d Cognito device data structure
 */
function generateDevice(d) {
  const device = {
    deviceKey: d.DeviceKey,
    createdAt: new Date(d.DeviceCreateDate * 1000),
    lastModifiedAt: new Date(d.DeviceLastModifiedDate * 1000),
    authenticatedAt: new Date(d.DeviceLastAuthenticatedDate * 1000),
    deviceStatus: null,
    deviceName: null,
    deviceLastIPUsed: null,
    deviceAgent: null,
  };
  if (d.DeviceAttributes) {
    d.DeviceAttributes.forEach((attr) => {
      if (attr.Name === 'device_status') {
        device.deviceStatus = attr.Value;
      }
      if (attr.Name === 'device_name') {
        device.deviceName = attr.Value;
        const parser = new UAParser();
        parser.setUA(device.deviceName);
        device.deviceAgent = parser.getResult();
      }
      if (attr.Name === 'last_ip_used') {
        device.deviceLastIPUsed = attr.Value;
      }
    });
  }
  return device;
}

export const AppContext = createContext({
  state: initialState,
  actions: {
    setAppState: () => { },
    handleError: () => { },
    authenticate: () => { },
    signIn: () => { },
    signOut: () => { },
    forgotPassword: () => { },
    forgotPasswordConfirm: () => { },
    signUp: () => { },
    signUpConfirm: () => { },
    resendSignUp: () => { },
    redirectToApp: () => { },
    redirectToMHubApp: () => { },
    requestNewPin: () => { },
    completeNewPassword: () => { },
    requestVerifyPin: () => { },
    confirmVerify: () => { },
    signInOauth: () => { },
    signOutOauth: () => { },
    listDevices: async () => [],
    getDevice: async () => null,
  },
});

class AppProvider extends PureComponent {
  static displayName = 'AppProvider';

  static propTypes = {
    children: PropTypes.node.isRequired,
    snackBarContext: PropTypes.shape({
      showSuccess: PropTypes.func,
      showError: PropTypes.func,
      dismissAll: PropTypes.func,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    Hub.listen('auth', this.hubCallback);
    this.state = {
      data: initialState,
    };
  }

  async componentDidMount() {
    await this.authenticate();

    const { access_token: accessToken } = getHash();
    if (accessToken) {
      await this.setStateAsync({ isADFS: true });
    }
  }

  hubCallback = async (capsule) => {
    // The Auth module will emit events when user signs in, signs out, etc
    const { channel, payload } = capsule;
    if (channel === 'auth' && payload.event === 'cognitoHostedUI') {
      await this.authenticate();
      const { data } = this.state;
      if (data.isAuthenticated) {
        const { snackBarContext } = this.props;
        if (snackBarContext) {
          snackBarContext.dismissAll();
          snackBarContext.showSuccess({ content: 'Successfully login.' });
        }
      }
    }
  }

  setStateAsync = (newState = {}) => new Promise((resolve) => {
    this.setState(prevState => ({
      data: Immutable.merge(prevState.data, newState),
    }), () => resolve(newState));
  });

  handleError = (e, isLogin = false) => {
    const { snackBarContext } = this.props;
    if (utils.isObj(e)) {
      let content = e.message.replace(/UserMigration failed with error/, '').trim();
      if (e.message.match(/Exception migrating user in app client/)) {
        switch (e.code) {
          case 'UserNotFoundException':
            content = isLogin ? 'Incorrect username or password' : 'User does not exist';
            break;
          case 'NotAuthorizedException':
            content = 'Incorrect username or password';
            break;
          default:
            content = 'An error occurred';
        }
      } else {
        switch (e.code) {
          case 'UserNotFoundException':
            content = isLogin ? 'Incorrect username or password' : 'User does not exist';
            break;
          case 'UserLambdaValidationException':
            if (e.message.includes('Device limit reached.')) {
              content = 'You are logged in into another device. Please logout before proceeding';
            }
            break;
          default:
        }
      }
      if (snackBarContext) {
        if (!content.endsWith('.')) {
          content += '.';
        }
        content += ` ${helplineMsg}`;
        snackBarContext.showError({ content });
      }
    } else if (utils.isString(e) && !e.match(/not authenticate/i)) {
      if (snackBarContext) {
        let content = e;
        if (!content.endsWith('.')) {
          content += '.';
        }
        content += ` ${helplineMsg}`;
        snackBarContext.showError({ content: e });
      }
    }
  }

  getDevice = () => new Promise(async (res, rej) => {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (err) {
      rej(err);
      return;
    }
    if (!user) {
      res(null);
      return;
    }
    try {
      user.getCachedDeviceKeyAndPassword();
    } catch (err) {
      rej(err);
      return;
    }
    user.getDevice({
      onSuccess: (result) => {
        if (result && result.Device) {
          const device = generateDevice(result.Device);
          res(device);
        }
      },
      onFailure: (err) => {
        rej(err);
      },
    });
  })

  forgetDevice = () => new Promise(async (res, rej) => {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (err) {
      rej(err);
      return;
    }
    if (!user) {
      res(null);
      return;
    }
    try {
      user.getCachedDeviceKeyAndPassword();
    } catch (err) {
      rej(err);
      return;
    }
    user.forgetDevice({
      onSuccess: (result) => {
        res(result);
      },
      onFailure: (err) => {
        rej(err);
      },
    });
  })

  listDevices = () => new Promise(async (res, rej) => {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (err) {
      rej(err);
      return;
    }
    if (!user) {
      res([]);
      return;
    }
    try {
      user.getCachedDeviceKeyAndPassword();
    } catch (err) {
      rej(err);
      return;
    }
    user.listDevices(10, null, {
      onSuccess: (result) => {
        if (result && result.Devices) {
          const devices = [];
          result.Devices.forEach((d) => {
            const device = generateDevice(d);
            devices.push(device);
          });
          res(devices);
        }
      },
      onFailure: (err) => {
        rej(err);
      },
    });
  })

  authenticate = async (firstTry = true) => {
    await this.setStateAsync({ loading: true });
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (error) {
      if (
        firstTry && (
          (utils.isString(error) && !error.match(/not authenticate/i)) || (
            utils.isObj(error) && error.code !== 'PasswordResetRequiredException'
            && (error.message.match(/not authenticate/i)
              || error.message.match(/token/i))
          )
        )
      ) {
        // delay retry to check new token
        await setTimeout(() => {
          this.authenticate(false);
        }, 1000);
        return;
      }
      const { data } = this.state;
      let { resetRequired } = data;
      if (error.code === 'PasswordResetRequiredException') {
        resetRequired = true;
      }
      await this.setStateAsync({
        loading: false, error, isError: true, resetRequired, isAuthenticated: false, isADFS: false, adfsProvider: '',
      });
      await this.handleError(error);
      return;
    }

    let res;
    try {
      res = await this.checkUserPhoneNumberVerified(user);
    } catch (error) {
      await this.setStateAsync({
        loading: false, error, isError: true, isAuthenticated: false, isADFS: false, adfsProvider: '',
      });
      await this.handleError(error);
      return;
    }

    const {
      verifyRequired, userGroups, isADFS, adfsProvider,
    } = res;
    if (verifyRequired) {
      await this.setStateAsync({
        loading: false, verifyRequired, isAuthenticated: false, userGroups, isADFS, adfsProvider,
      });
      return;
    }

    await this.setStateAsync({
      loading: false, isAuthenticated: true, userGroups, isADFS, adfsProvider,
    });
  }

  checkUserPhoneNumberVerified = user => new Promise(async (resolve, reject) => {
    let verifyRequired = '';
    let adfsProvider = '';

    const attributes = user.getSignInUserSession().getIdToken().decodePayload();
    const { 'cognito:groups': userGroups, phone_number_verified: mobileVerified } = attributes;
    let { identities } = attributes;
    if (identities) {
      if (utils.isString(identities)) {
        identities = JSON.parse(identities);
      }
      if (Array.isArray(identities)) {
        adfsProvider = identities.reduce((result, identity) => {
          if (result === '' && identity.providerName) {
            return identity.providerName;
          }
          return result;
        }, adfsProvider);
      }
    } else {
      if (utils.objHasOwnProperty(attributes, 'phone_number_verified') && !mobileVerified) {
        verifyRequired = 'phone_number';
      }
      if (verifyRequired) {
        try {
          await Auth.verifyCurrentUserAttribute(verifyRequired);
        } catch (error) {
          return reject(error);
        }
        const { snackBarContext } = this.props;
        if (snackBarContext) {
          snackBarContext.dismissAll();
          snackBarContext.showSuccess({ content: `Your ${verifyRequired.replace('_', ' ')} require verification.` });
        }
      }
    }


    return resolve({
      verifyRequired, userGroups, isADFS: !!adfsProvider, adfsProvider,
    });
  })

  signIn = async (inputEmail, loginPassword) => {
    await this.setStateAsync({
      loading: true, email: null, pinMedium: null, pinSentTo: null,
    });
    eraseCookieFromAllPaths();
    const email = inputEmail.toLowerCase().trim();
    setUser(email);

    // redirect to ADFS if email domain matched
    const authConfig = Auth.configure();
    const { oauth } = authConfig;
    if (oauth && config.bugsnag.RELEASE_STAGE === 'production') {
      if (email.match(/@spsetia/i)) {
        this.signInOauth('SPS');
        return;
      }
      if (email.match(/@uemsunrise/i)) {
        this.signInOauth('UEM');
        return;
      }
    }

    let user;
    try {
      user = await Auth.signIn(email, loginPassword);
    } catch (error) {
      const { data } = this.state;
      let { resetRequired } = data;
      if (error.code === 'PasswordResetRequiredException') {
        resetRequired = true;
      }
      await this.setStateAsync({
        loading: false, error, isError: true, resetRequired, email: resetRequired ? email : '',
      });
      await this.handleError(error, true);
      return;
    }
    const { challengeName } = user;
    if (challengeName === 'NEW_PASSWORD_REQUIRED') {
      await this.setStateAsync({ loading: false, loginPassword, email });
      return;
    }

    let res;
    try {
      res = await this.checkUserPhoneNumberVerified(user);
    } catch (error) {
      await this.setStateAsync({
        loading: false, error, isError: true, isAuthenticated: false,
      });
      await this.handleError(error, true);
      return;
    }

    const { verifyRequired, userGroups } = res;
    if (verifyRequired) {
      await this.setStateAsync({
        loading: false, verifyRequired, isAuthenticated: false, userGroups,
      });
      return;
    }

    const { snackBarContext } = this.props;
    if (snackBarContext) {
      snackBarContext.dismissAll();
      snackBarContext.showSuccess({ content: 'Successfully login.' });
    }
    await this.setStateAsync({
      loading: false,
      isAuthenticated: true,
      email: null,
      userGroups,
    });
  }

  signInOauth = provider => new Promise(async (resolve, reject) => {
    const authConfig = Auth.configure();
    const { oauth, userPoolWebClientId: clientId } = authConfig;
    if (!oauth) {
      return reject();
    }

    // TODO: check provider

    const {
      domain,
      redirectSignIn,
      scope,
      responseType,
    } = oauth;

    const query = {
      client_id: clientId,
      response_type: responseType,
      scope: scope.join(' '),
      redirect_uri: redirectSignIn,
    };
    if (provider) {
      query.identity_provider = provider.toUpperCase();
    }

    // The url of the Cognito Hosted UI
    const url = parseParams(`https://${domain}/oauth2/authorize`, query);

    // Launch hosted UI
    window.location.assign(url);
    return resolve();
  })

  signOutOauth = async (provider) => {
    const authConfig = Auth.configure();
    const { oauth, userPoolWebClientId: clientId } = authConfig;
    if (!oauth) {
      return;
    }

    const {
      domain,
      redirectSignOut,
    } = oauth;

    const query = {
      client_id: clientId,
    };

    let logoutURL = redirectSignOut;
    if (provider) {
      switch (provider.toUpperCase()) {
        case 'SPS':
          logoutURL = 'https://sts.spsetia.com.my/adfs/ls/?wa=wsignout1.0';
          break;
        case 'UEM':
          logoutURL = 'https://login.microsoftonline.com/9d61dd22-7a13-4a2c-97d6-d32098cc1dc2/saml2';
          break;
        default:
          logoutURL = redirectSignOut;
      }
    }
    query.logout_uri = logoutURL;

    // The url of the Cognito Hosted UI
    const url = parseParams(`https://${domain}/logout`, query);

    // Launch hosted UI
    window.location.assign(url);
  }

  signOut = async (isGlobal = false, showSnackBar = true, errorMessage = '') => {
    await this.setStateAsync({ loading: true });

    try {
      await this.forgetDevice();
    } catch (err) {
      window.bugsnagClient.notify(err);
    }

    try {
      await Auth.signOut({ global: isGlobal });
    } catch (error) {
      if (!error.message.match(/revoked/i)) {
        await this.setStateAsync({ loading: false, error, isError: true });
        await this.handleError(error);
        return;
      }
      await Auth.signOut();
    }
    const { snackBarContext } = this.props;
    if (showSnackBar && snackBarContext) {
      snackBarContext.dismissAll();
      snackBarContext.showSuccess({ content: 'You\'re now logout.' });
    }
    const { data } = this.state;
    const { adfsProvider } = data;
    if (adfsProvider) {
      await this.signOutOauth(adfsProvider);
    }
    if (errorMessage && snackBarContext) {
      snackBarContext.dismissAll();
      snackBarContext.showError({ content: errorMessage });
    }
    if (errorMessage) {
      // eslint-disable-next-line no-alert
      alert(errorMessage);
    }
    await this.setStateAsync({ loading: false, isAuthenticated: false, userGroups: [] });
  }

  forgotPassword = async (inputEmail) => {
    const email = inputEmail.toLowerCase().trim();
    await this.setStateAsync({
      loading: true, email: null, pinMedium: null, pinSentTo: null,
    });
    // redirect to ADFS if email domain matched
    const authConfig = Auth.configure();
    const { oauth } = authConfig;
    if (oauth) {
      let title = '';
      if (email.match(/@spsetia/i)) {
        title = 'SP Setia';
      }
      if (email.match(/@uemsunrise/i)) {
        title = 'UEM Sunrise';
      }
      if (title) {
        const error = `For ${title} user, please contact your System Administrator to reset your password.`;
        if (error) {
          await this.setStateAsync({ loading: false, error, isError: true });
          await this.handleError(error);
          return;
        }
      }
    }
    let res;
    setUser(email);
    try {
      res = await Auth.forgotPassword(email);
    } catch (error) {
      await this.setStateAsync({ loading: false, error, isError: true });
      await this.handleError(error);
      return;
    }
    const { data } = this.state;
    let { pinSentTo, pinMedium } = data;
    const { snackBarContext } = this.props;
    if (snackBarContext) {
      snackBarContext.dismissAll();
      const { CodeDeliveryDetails } = res;
      pinSentTo = CodeDeliveryDetails.Destination;
      pinMedium = CodeDeliveryDetails.AttributeName.replace('_', ' ');
      snackBarContext.showSuccess({ content: `PIN sent to ${pinMedium}: ${pinSentTo}.` });
    }
    await this.setStateAsync({
      email,
      pinMedium,
      pinSentTo,
      loading: false,
      pinRequestAt: moment().toDate(),
    });
  }

  requestNewPin = async (inputEmail = this.state.data.email) => {
    await this.setStateAsync({ loading: true });
    const email = inputEmail.toLowerCase().trim();
    setUser(email);
    let res;
    try {
      res = await Auth.forgotPassword(email);
    } catch (error) {
      await this.setStateAsync({ loading: false, error, isError: true });
      await this.handleError(error);
      return Promise.reject(error);
    }
    const { data } = this.state;
    let { pinSentTo, pinMedium } = data;
    const { snackBarContext } = this.props;
    if (snackBarContext) {
      snackBarContext.dismissAll();
      const { CodeDeliveryDetails } = res;
      pinSentTo = CodeDeliveryDetails.Destination;
      pinMedium = CodeDeliveryDetails.AttributeName.replace('_', ' ');
      snackBarContext.showSuccess({ content: `PIN sent to ${pinMedium}: ${pinSentTo}.` });
    }
    await this.setStateAsync({
      pinMedium,
      pinSentTo,
      loading: false,
      pinRequestAt: moment().toDate(),
    });
    return Promise.resolve(res);
  }

  forgotPasswordConfirm = async (pin, password, inputEmail = this.state.data.email) => {
    await this.setStateAsync({ loading: true });
    const email = inputEmail.toLowerCase().trim();
    setUser(email);
    try {
      await Auth.forgotPasswordSubmit(email, pin, password);
    } catch (error) {
      await this.setStateAsync({ loading: false, error, isError: true });
      await this.handleError(error);
      return;
    }
    const { snackBarContext } = this.props;
    if (snackBarContext) {
      snackBarContext.dismissAll();
      snackBarContext.showSuccess({ content: 'Password has successfully changed.' });
    }
    await this.setStateAsync({
      loading: false,
      email: null,
      pinMedium: null,
      pinSentTo: null,
      resetRequired: false,
    });

    await this.signIn(email, password);
  }

  signUp = async (inputEmail, password) => {
    await this.setStateAsync({
      loading: true, email: null, pinMedium: null, pinSentTo: null,
    });
    const email = inputEmail.toLowerCase().trim();
    setUser(email);
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
      });
    } catch (error) {
      await this.setStateAsync({ loading: false, error, isError: true });
      await this.handleError(error);
      return;
    }
    await this.setStateAsync({ loading: false, email });
  }

  resendSignUp = async (inputEmail = this.state.data.email) => {
    await this.setStateAsync({ loading: true });
    const email = inputEmail.toLowerCase().trim();
    setUser(email);
    let res;
    try {
      res = await Auth.resendSignUp(email);
    } catch (error) {
      await this.setStateAsync({ loading: false, error, isError: true });
      await this.handleError(error);
      return Promise.reject(error);
    }
    const { data } = this.state;
    let { pinSentTo, pinMedium } = data;
    const { snackBarContext } = this.props;
    if (snackBarContext) {
      snackBarContext.dismissAll();
      const { CodeDeliveryDetails } = res;
      pinSentTo = CodeDeliveryDetails.Destination;
      pinMedium = CodeDeliveryDetails.AttributeName.replace('_', ' ');
      snackBarContext.showSuccess({ content: `PIN sent to ${pinMedium}: ${pinSentTo}.` });
    }
    await this.setStateAsync({ loading: false, pinSentTo, pinMedium });
    return Promise.resolve(res);
  }

  signUpConfirm = async (pin, inputEmail = this.state.data.email) => {
    await this.setStateAsync({ loading: true });
    const email = inputEmail.toLowerCase().trim();
    setUser(email);
    let res;
    try {
      res = await Auth.confirmSignUp(email, pin);
    } catch (error) {
      await this.setStateAsync({ loading: false, error, isError: true });
      await this.handleError(error);
      return Promise.reject(error);
    }
    const { snackBarContext } = this.props;
    if (snackBarContext) {
      snackBarContext.dismissAll();
      snackBarContext.showSuccess({ content: 'Your account is now confirmed. Please proceed to login.' });
    }
    // NOTE: We are unable to help user login if user continue signup flow thru login
    //  (which mean user stop signup flow at account
    //   confirmation step and continue again thru login)
    await this.setStateAsync({
      loading: false,
      email: null,
      pinSentTo: null,
      pinMedium: null,
    });
    return Promise.resolve(res);
  }

  completeNewPassword = async (password) => {
    await this.setStateAsync({ loading: true });
    const { data } = this.state;
    let res;
    try {
      const user = await Auth.signIn(data.email, data.loginPassword);
      res = await Auth.completeNewPassword(user, password);
    } catch (error) {
      await this.setStateAsync({ loading: false, error, isError: true });
      await this.handleError(error);
      return;
    }
    const { snackBarContext } = this.props;
    if (snackBarContext) {
      snackBarContext.dismissAll();
      snackBarContext.showSuccess({ content: 'Password has successfully changed.' });
    }
    const userGroups = res.getSignInUserSession().getIdToken().decodePayload()['cognito:groups'];
    await this.setStateAsync({
      loading: false,
      isAuthenticated: true,
      loginPassword: null,
      email: null,
      pinSentTo: null,
      pinMedium: null,
      userGroups,
    });
  }

  requestVerifyPin = async () => {
    await this.setStateAsync({ loading: true });
    const { data } = this.state;
    const { verifyRequired } = data;
    let res;
    try {
      res = await Auth.verifyCurrentUserAttribute(verifyRequired);
    } catch (error) {
      await this.setStateAsync({ loading: false, error, isError: true });
      await this.handleError(error);
      return Promise.reject(error);
    }

    const { snackBarContext } = this.props;
    if (snackBarContext) {
      snackBarContext.dismissAll();
      snackBarContext.showSuccess({ content: `PIN sent to ${verifyRequired.replace('_', ' ')}.` });
    }

    await this.setStateAsync({ loading: false });
    return Promise.resolve(res);
  }

  confirmVerify = async (pin) => {
    await this.setStateAsync({ loading: true });
    const { data } = this.state;
    const { verifyRequired } = data;
    let res;
    try {
      await Auth.verifyCurrentUserAttributeSubmit(verifyRequired, pin);
      // force update cache attributes
      res = await Auth.currentAuthenticatedUser({ bypassCache: true });
    } catch (error) {
      await this.setStateAsync({ loading: false, error, isError: true });
      await this.handleError(error);
      return;
    }

    const { snackBarContext } = this.props;
    if (snackBarContext) {
      snackBarContext.dismissAll();
      snackBarContext.showSuccess({ content: `Your ${verifyRequired.replace('_', ' ')} has successfully verified.` });
    }

    const userGroups = res.getSignInUserSession().getIdToken().decodePayload()['cognito:groups'];
    await this.setStateAsync({
      loading: false, verifyRequired: null, isAuthenticated: true, userGroups,
    });
  }

  redirectToApp = async () => {
    await this.setStateAsync({ loading: true });

    const { redirect } = getQuery();
    const { state } = getHash();
    const base64 = redirect || state;
    if (base64) {
      const url = decodeRedirect(base64);
      if (url.startsWith('http')) {
        window.location.replace(url);
      }
      return;
    }

    const { data } = this.state;
    const { userGroups } = data;
    if (utils.arrayHasItems(userGroups)) {
      const destination = userGroups.reduce((result, group) => {
        if (result === config.endpoints.adminURL) {
          return result;
        }
        if (group === LAWYER) {
          return config.endpoints.lawyerURL;
        }
        if (group === MARKETPLACE_AGENT) {
          return config.endpoints.marketplaceURL;
        }
        if (group === DEVELOPER_SITEPLAN) {
          return config.endpoints.siteplanURL;
        }
        if (!result) {
          if (USER_ADMIN_GROUPS.some(g => g === group)) {
            return config.endpoints.adminURL;
          }
          if (USER_BANKER_GROUPS.some(g => g === group)) {
            return config.endpoints.bankURL;
          }
          if (USER_DEVELOPER_GROUPS.some(g => g === group)) {
            // TODO: in the future, all developer will use same book-web
            // FIXME: return config.endpoints.developerURL;

            // call mhub api to get correct url to redirect
            return 'DEVELOPER_REDIRECT';
          }
        }
        return result;
      }, null);
      if (destination && destination !== 'DEVELOPER_REDIRECT') {
        window.location.replace(destination);
        return;
      }
    }

    this.redirectToMHubApp();
  }

  redirectToMHubApp = async (retry = true) => {
    const opts = { credentials: 'include' };
    let res;
    try {
      res = await fetchM(`${config.endpoints.apiURL}/authenticated/`, opts);
    } catch (error) {
      if (retry) {
        setTimeout(() => {
          this.redirectToMHubApp(false);
        }, 1000);
        return;
      }
      await this.signOut(false, false, noAccErrMsg);
      return;
    }

    const user = await res.json();
    if (!user || (user && !(user.groups || user.hasAccess))) {
      this.signOut();
      return;
    }
    const destination = user.groups.reduce((result, group) => {
      if (result === config.endpoints.adminURL) {
        return result;
      }
      if (group.code === DEVELOPER_SITEPLAN) {
        return config.endpoints.siteplanURL;
      }
      if (!result) {
        if (group.code === ADMIN_BASIC) {
          return config.endpoints.adminURL;
        }
        if (group.code === MORTGAGE_BANKER) {
          return config.endpoints.bankURL;
        }
        if (group.code === DEVELOPER_EXECUTIVE) {
          // Direct to SHOWROOM if available
          if (user.affiliate && user.affiliate.company && user.affiliate.company.apps) {
            const app = user.affiliate.company.apps.find(a => a.appID === SHOWROOM);
            if (app && app.webURL && app.hasAccess) {
              return app.webURL;
            }
          }
          return config.endpoints.developerURL;
        }
      }
      return result;
    }, null);
    if (destination) {
      window.location.replace(destination);
    }
  }

  static initialState = initialState;

  static Consumer = AppContext.Consumer;

  render() {
    const { children } = this.props;
    const { data } = this.state;
    const value = {
      state: data,
      actions: {
        setAppState: this.setStateAsync,
        handleError: this.handleError,
        authenticate: this.authenticate,
        signIn: this.signIn,
        signOut: this.signOut,
        forgotPassword: this.forgotPassword,
        forgotPasswordConfirm: this.forgotPasswordConfirm,
        signUp: this.signUp,
        resendSignUp: this.resendSignUp,
        signUpConfirm: this.signUpConfirm,
        redirectToApp: this.redirectToApp,
        redirectToMHubApp: this.redirectToMHubApp,
        requestNewPin: this.requestNewPin,
        completeNewPassword: this.completeNewPassword,
        requestVerifyPin: this.requestVerifyPin,
        confirmVerify: this.confirmVerify,
        signInOauth: this.signInOauth,
        signOutOauth: this.signOutOauth,
        listDevices: this.listDevices,
        getDevice: this.getDevice,
      },
    };
    return (
      <AppContext.Provider value={value}>
        {children}
      </AppContext.Provider>
    );
  }
}

export default withSnackBar(AppProvider);
