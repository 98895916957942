/* eslint-disable max-len */
import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export function dayClass() {
  const day = moment().day();
  switch (day) {
    case 0:
      return 'bg_sun';
    case 1:
      return 'bg_mon';
    case 2:
      return 'bg_tue';
    case 3:
      return 'bg_wed';
    case 4:
      return 'bg_thu';
    case 5:
      return 'bg_fri';
    case 6:
      return 'bg_sat';
    default:
      return 'bg_default';
  }
}

// return class names ex. background.cny / background.cny_2 / background.cny_3
export function randomClassByHour(className, randomCount = 1) {
  if (randomCount && randomCount > 1) {
    const currentHour = new Date().getHours();
    const random = (currentHour % randomCount) + 1;
    if (random > 1) {
      return `${className}_${random}`;
    }
  }
  return className;
}

// return class names ex. background.cny / background.cny_2 / background.cny_3
export function randomClass(className, randomCount = 1) {
  if (randomCount && randomCount > 1) {
    const random = Math.floor(Math.random() * (randomCount - 1 + 1) + 1);
    if (random > 1) {
      return `${className}_${random}`;
    }
  }
  return className;
}

export const transformOrigins = ['bottom left', 'bottom right', 'top left', 'top right', 'bottom', 'top', 'right', 'left'];
export const transformOrigin = transformOrigins[Math.floor(Math.random() * transformOrigins.length)];
