import React from 'react';
import { SnackBarProvider } from '@mhub/web-components';

import { AppProvider } from './context';
import App from './containers/App';

const Root = () => (
  <SnackBarProvider>
    <AppProvider>
      <App />
    </AppProvider>
  </SnackBarProvider>
);

export default Root;
