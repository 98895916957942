/* eslint react/prop-types: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { AppContext } from '../context';
import {
  getQuery, decodeRedirect, getHash, parseParams,
} from '../utils/url';
import MHubRedirect from '../containers/Redirect';

const RouteUnauthenticated = ({ component: C, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <AppContext.Consumer>
        {({ state }) => {
          const { location } = props;
          const query = getQuery(location);
          const { redirect, ...queryRest } = query;
          const hash = getHash(location);
          if (!state.isAuthenticated) {
            let url;
            if (state.resetRequired && location.pathname !== '/reset_password') {
              url = '/reset_password';
            }
            if (state.loginPassword && location.pathname !== '/new_password') {
              url = '/new_password';
            }
            if (state.verifyRequired && location.pathname !== '/verify') {
              url = '/verify';
            }
            if (url) {
              return <Redirect to={parseParams(url, query, hash)} />;
            }
            return <C {...props} />;
          }
          if (redirect) {
            const url = decodeRedirect(redirect);
            if (url.startsWith('http')) {
              return <MHubRedirect to={url} />;
            }
            return <Redirect to={parseParams(url, queryRest, hash)} />;
          }
          return <Redirect to={parseParams('/', query, hash)} />;
        }}
      </AppContext.Consumer>
    )}
  />
);

RouteUnauthenticated.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
};

export default RouteUnauthenticated;
