import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { Container } from '@mhub/web-components';

import { AppContext } from '../../context';
import Notice from '../Notice';
import AppHeader from '../../components/AppHeader';
import './styles.css';

export default class Redirect extends PureComponent {
  noticeRef = createRef()

  static propTypes = {
    to: PropTypes.string,
    match: PropTypes.shape({
      params: PropTypes.shape({
        provider: PropTypes.string,
      }),
    }).isRequired,
    history: PropTypes.shape({
      goBack: PropTypes.func,
      replace: PropTypes.func,
    }).isRequired,
  };

  static defaultProps = {
    to: '',
  }

  componentDidMount() {
    const { match, history, to } = this.props;
    if (to) {
      this.showNotice();
      return;
    }
    const { actions, state } = this.context;
    const { params } = match;
    const { provider } = params;
    if (state.isAuthenticated) {
      this.showNotice();
    } else if (provider) {
      actions.signInOauth(provider)
        .catch(() => {
          history.replace('/not_found');
        });
    }
  }

  handleRedirect = () => {
    const { to } = this.props;
    const { actions, state } = this.context;
    if (to) {
      window.location.replace(to);
      return;
    }
    if (state.isAuthenticated) {
      actions.redirectToApp();
    }
  }

  static contextType = AppContext;

  showNotice() {
    if (this.noticeRef.current) {
      this.noticeRef.current.show();
    }
  }

  render() {
    return (
      <Container className="Redirect" noContent>
        <Notice
          ref={this.noticeRef}
          onClose={this.handleRedirect}
        />
        <AppHeader header="Please wait" loading>
          <p>
            We are redirecting you to the app now
          </p>
        </AppHeader>
      </Container>
    );
  }
}
