/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Container,
  Row,
  Checkbox,
  Button,
  Loader,
} from '@mhub/web-components';
import Auth from '@aws-amplify/auth';
import moment from 'moment';

import { AppContext } from '../../context';
import * as storage from '../../utils/storage';
import './styles.css';
import config from '../../config';

const NOTIFICATION_DATA_INIT = {
  id: null,
  url: null,
  show: false,
};

function getUserGroupPath(groups = []) {
  if (groups.some(g => g === 'ADMIN_BASIC')) {
    return '/groups/admin/index.json';
  }
  if (groups.some(g => g === 'AGENT_AGENCY')) {
    return '/groups/agency/index.json';
  }
  if (groups.some(g => g === 'AGENT')) {
    return '/groups/agent/index.json';
  }
  if (groups.some(g => g === 'DEVELOPER_HEADQUARTERS')) {
    return '/groups/developer_headquarters/index.json';
  }
  if (groups.some(g => g === 'DEVELOPER_EXECUTIVE')) {
    return '/groups/developer_executive/index.json';
  }
  if (groups.some(g => g === 'LAWYER')) {
    return '/groups/lawyer/index.json';
  }
  if (groups.some(g => g === 'HEADQUARTERS')) {
    return '/groups/banker_headquarters/index.json';
  }
  if (groups.some(g => g === 'MORTGAGE_BANKER')) {
    return '/groups/banker/index.json';
  }
  return null;
}

async function getRecentNotice(items = []) {
  let notice = { show: false };

  if (items.length > 0) {
    let username = '';
    try {
      ({ username } = await Auth.currentAuthenticatedUser());
    } catch (e) {
      window.bugsnagClient.notify(e);
    }

    const now = moment();
    const timeFormat = 'YYYY-MM-DD hh:mm:ss Z';

    const ii = items.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    ii.some((i) => {
      const {
        name: id, permalink: url, startDate, endDate,
      } = i;
      const remind = storage.getItem(`notice:${username}:${id}`);
      if (
        (remind === 'yes' || !remind)
        && now.isAfter(moment(startDate, timeFormat))
        && now.isBefore(moment(endDate, timeFormat))
      ) {
        notice = { id, url, show: true };
        return true;
      }
      return false;
    });
  }

  return notice;
}

export default class Notice extends Component {
  static propTypes = {
    onClose: PropTypes.func,
  }

  static defaultProps = {
    onClose: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      ready: false,
      error: false,
      visible: false,
      remind: false,
      data: Object.assign({}, NOTIFICATION_DATA_INIT),
    };
  }

  async getNotification() {
    await this.setStateAsync({ loading: true, ready: false });

    let notices;
    const { state: { userGroups } } = this.context;
    const groupPath = getUserGroupPath(userGroups);

    if (!groupPath) {
      await this.setStateAsync({ loading: false, error: true, ready: true });
      return;
    }

    try {
      const res = await fetch(`${config.endpoints.notificationURL}${groupPath}`, {
        mode: 'cors',
      });
      notices = await res.json();
    } catch (e) {
      await this.setStateAsync({ loading: false, error: true, ready: true });
      return;
    }
    const data = Object.assign({}, NOTIFICATION_DATA_INIT);
    if (notices && notices.data && notices.data.items) {
      const newData = await getRecentNotice(notices.data.items);
      if (newData.id) {
        data.id = newData.id;
      }
      if (newData.url) {
        data.url = newData.url;
      }
      if (newData.show) {
        data.show = newData.show;
      }
    } else {
      await this.setStateAsync({ loading: false, error: true, ready: true });
      return;
    }

    await this.setStateAsync({ loading: false, ready: true, data });
  }

  setStateAsync = (state = {}) => new Promise(res => this.setState(state, () => res(state)))

  handleRemind = () => {
    const { remind } = this.state;
    this.setState({ remind: !remind });
  }

  handleOnOpen = async () => {
  }

  handleDismiss = async () => {
    const { remind, data: { id } } = this.state;
    let username = '';
    try {
      ({ username } = await Auth.currentAuthenticatedUser());
    } catch (e) {
      window.bugsnagClient.notify(e);
    }
    storage.setItem(`notice:${username}:${id}`, remind ? 'yes' : 'no');
    await this.setStateAsync({ visible: false });
  }

  continue = async () => {
    await this.setStateAsync({ visible: false });
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  }

  static contextType = AppContext;

  async show() {
    await this.getNotification();

    let username = '';
    try {
      ({ username } = await Auth.currentAuthenticatedUser());
    } catch (e) {
      window.bugsnagClient.notify(e);
    }

    const { ready, data: { id, show } } = this.state;
    if (!ready) {
      await this.continue();
    }
    const remind = storage.getItem(`notice:${username}:${id}`);

    if (show && (!remind || remind === 'yes')) {
      await this.setStateAsync({ visible: true });
      return;
    }
    await this.continue();
  }

  renderIFrame() {
    const {
      data: { url },
    } = this.state;
    const iframe = `<iframe class="frame-iframe" src="${url}"></iframe>`;
    return (
      <div
        className="frame-container"
        dangerouslySetInnerHTML={{
          __html: iframe,
        }}
      />
    );
  }

  renderContent() {
    const {
      error,
      ready,
      remind,
    } = this.state;
    return (
      <Container noContent textAlign="center">
        <Row middle center>
          {
            error
              ? (
                <div>
                  <h3>Oops! We&#39;re having trouble loading your notice.</h3>
                  <p>It&#39;s not you. It&#39;s us. Please try again.</p>
                </div>
              )
              : this.renderIFrame()
          }
        </Row>
        <Row center={!!error} between={!error} className="actions">
          {
            !error && (
              <Checkbox
                onChange={this.handleRemind}
                special
                checked={remind}
              >
                Remind me later
              </Checkbox>
            )
          }
          <Button
            color="blue"
            onClick={this.handleDismiss}
            disabled={!ready}
          >
            Dismiss
          </Button>
        </Row>
      </Container>
    );
  }

  render() {
    const {
      visible,
      loading,
    } = this.state;
    return (
      <Modal
        className="Notice"
        visible={visible}
        onClose={this.continue}
        showCloseButton={false}
        basic
        dimOverlayClickable={false}
      >
        <Row middle center>
          <div className="column">
            {loading ? <Loader color="white" /> : this.renderContent()}
          </div>
        </Row>
      </Modal>
    );
  }
}
