export const SUPER_ADMIN = 'SUPER_ADMIN';
export const ADMIN_COMMUNITY = 'ADMIN_COMMUNITY';
export const ADMIN_BASIC = 'ADMIN_BASIC';
export const ADMIN = 'ADMIN';
export const HEADQUARTERS = 'HEADQUARTERS';
export const MARKETPLACE_AGENT = 'MARKETPLACE_AGENT';
export const MORTGAGE_BANKER = 'MORTGAGE_BANKER';
export const TEAM_LEADER = 'TEAM_LEADER';
export const DEVELOPER_HEADQUARTERS = 'DEVELOPER_HEADQUARTERS';
export const DEVELOPER_BRANCH_LEADER = 'DEVELOPER_BRANCH_LEADER';
export const DEVELOPER_BRANCH_LEADER_SUPERVISOR = 'DEVELOPER_BRANCH_LEADER_SUPERVISOR';
export const DEVELOPER_BRANCH_LEADER_SUPERVISOR_II = 'DEVELOPER_BRANCH_LEADER_SUPERVISOR_II';
export const DEVELOPER_TEAM_LEADER = 'DEVELOPER_TEAM_LEADER';
export const DEVELOPER_EXECUTIVE = 'DEVELOPER_EXECUTIVE';
export const DEVELOPER_SITEPLAN = 'DEVELOPER_SITEPLAN';
export const AFFILIATE_I = 'AFFILIATE_I';
export const AFFILIATE_II = 'AFFILIATE_II';
export const LAWYER = 'LAWYER';


const USER_GROUPS = [
  SUPER_ADMIN,
  ADMIN_COMMUNITY,
  ADMIN_BASIC,
  ADMIN,
  HEADQUARTERS,
  TEAM_LEADER,
  MARKETPLACE_AGENT,
  MORTGAGE_BANKER,
  DEVELOPER_HEADQUARTERS,
  DEVELOPER_BRANCH_LEADER,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR_II,
  DEVELOPER_TEAM_LEADER,
  DEVELOPER_EXECUTIVE,
  DEVELOPER_SITEPLAN,
  AFFILIATE_I,
  AFFILIATE_II,
  LAWYER,
];

export const USER_ADMIN_GROUPS = [
  SUPER_ADMIN,
  ADMIN_COMMUNITY,
  ADMIN_BASIC,
  ADMIN,
];

export const USER_BANKER_GROUPS = [
  HEADQUARTERS,
  TEAM_LEADER,
  MORTGAGE_BANKER,
];

export const USER_DEVELOPER_GROUPS = [
  DEVELOPER_HEADQUARTERS,
  DEVELOPER_BRANCH_LEADER,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR,
  DEVELOPER_BRANCH_LEADER_SUPERVISOR_II,
  DEVELOPER_TEAM_LEADER,
  DEVELOPER_EXECUTIVE,
  AFFILIATE_I,
  AFFILIATE_II,
];

export const USER_OTHER_GROUPS = [
  LAWYER,
  MARKETPLACE_AGENT,
];

export const USER_GROUP_GROUPS = [
  { text: 'Admin', value: USER_ADMIN_GROUPS },
  { text: 'Banker', value: USER_BANKER_GROUPS },
  { text: 'Developer', value: USER_DEVELOPER_GROUPS },
  { text: 'Other', value: USER_OTHER_GROUPS },
];

export default USER_GROUPS;
