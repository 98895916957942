import 'whatwg-fetch';
import clone from 'clone';

// eslint-disable-next-line import/prefer-default-export
export function fetchM(url, options = {}) {
  const opts = Object.assign({}, options);
  const headers = opts.headers ? clone(opts.headers) : {};
  let { origin } = window.location;
  if (!origin) {
    const { protocol, hostname, port } = window.location;
    origin = `${protocol}//${hostname}`;
    origin += `${(port ? `:${port}` : '')}`;
  }
  headers['X-Origin'] = origin;
  opts.headers = headers;
  return fetch(url, opts)
    .then((res) => {
      if (!res.ok) {
        let message = 'An error occurred';
        if (res.status === 401) {
          message = 'Please login';
        }
        if (res.status === 404) {
          message = 'Not found';
        }
        throw new Error(message);
      }
      return res;
    });
}
