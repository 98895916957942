import qs from 'query-string';
import { utils } from '@mhub/web-components';

import config from '../config';

export const getQuery = (location = window.location) => qs.parse(location.search);

export const getHash = (location = window.location) => qs.parse(location.hash);

export function parseParams(url, query = {}, hash = {}) {
  let parsedURL = url;
  if (query && utils.objHasKeys(query)) {
    parsedURL += `?${qs.stringify(query)}`;
  }
  if (hash && utils.objHasKeys(hash)) {
    parsedURL += `#${qs.stringify(hash)}`;
  }
  return parsedURL;
}

export function encodeRedirect(url = '/') {
  let nextBase64 = '';
  if (url) {
    try {
      nextBase64 = btoa(url);
    } catch (e) {
      window.bugsnagClient.notify(e);
    }
  }
  return nextBase64;
}

export function decodeRedirect(nextBase64) {
  let url = '';
  if (nextBase64) {
    try {
      url = atob(nextBase64);
    } catch (e) {
      window.bugsnagClient.notify(e);
    }
  }
  return url;
}

// This function will attempt to remove a cookie from all paths.
// Source: https://stackoverflow.com/a/33366171
// Modified by Orson
export function eraseCookieFromAllPaths(name = 'CognitoIdentityServiceProvider') {
  const cookies = document.cookie.split('; ');
  if (cookies.length < 1) {
    return;
  }

  const cognitoCookies = cookies.reduce((result, cookie) => {
    if (cookie.startsWith(name)) {
      const index = cookie.indexOf('=');
      if (index >= 0) {
        result.push(cookie.substring(0, index + 1));
      }
    }
    return result;
  }, []);

  const expires = 'expires=Thu, 01-Jan-1970 00:00:01 GMT';
  const domain = `domain=${config.cookie.domain}`;
  const path = 'path=/'; // login app only has cookie on this path
  cognitoCookies.forEach((cookie) => {
    document.cookie = [cookie, expires, domain, path].join('; ');
  });
}
