import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Button } from '@mhub/web-components';

import AppHeader from '../AppHeader';
import './styles.css';

export default class ErrorBoundary extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error) {
    // You can also log the error to an error reporting service
    window.bugsnagClient.notify(error);
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <Container className="Error" scrollable noContent>
          <AppHeader header="Error">
            <p>
              Sorry, there was a problem loading the page.
            </p>
          </AppHeader>
          <Button
            as={Link}
            color="blue"
            ripple
            to="/"
            replace
          >
            Reload
          </Button>
        </Container>
      );
    }
    const { children } = this.props;
    return children;
  }
}
