import React from 'react';
import PropTypes from 'prop-types';
import { Header, Logo } from '@mhub/web-components';

import './styles.css';

const AppHeader = ({ header, children, loading }) => (
  <div className="app-header">
    <Logo className="logo" alt="Logo" loading={loading} />
    <Header as="h4" bold={false} fluid>
      {header}
    </Header>
    {children}
  </div>
);

AppHeader.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
};

AppHeader.defaultProps = {
  header: '',
  children: null,
  loading: false,
};

export default AppHeader;
