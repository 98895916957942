import React from 'react';
import { Logo } from '@mhub/web-components';

import './styles.css';

const Loading = () => (
  <div className="Loading">
    <Logo color="white" loading />
  </div>
);

export default Loading;
