import { lazy } from 'react';

function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const AsyncForgotPassword = lazy(() => retry(() => import('./containers/ForgotPassword')));
export const AsyncHome = lazy(() => retry(() => import('./containers/Home')));
export const AsyncLogin = lazy(() => retry(() => import('./containers/Login')));
export const AsyncLogout = lazy(() => retry(() => import('./containers/Logout')));
export const AsyncNotFound = lazy(() => retry(() => import('./containers/NotFound')));
export const AsyncRedirect = lazy(() => retry(() => import('./containers/Redirect')));
export const AsyncRegister = lazy(() => retry(() => import('./containers/Register')));
export const AsyncRegisterRedirect = lazy(() => retry(() => import('./containers/RegisterRedirect')));
export const AsyncResetPassword = lazy(() => retry(() => import('./containers/ResetPassword')));
export const AsyncNewPassword = lazy(() => retry(() => import('./containers/NewPassword')));
export const AsyncLawyerRegister = lazy(() => retry(() => import('./containers/LawyerRegister')));
export const AsyncVerifyAttribute = lazy(() => retry(() => import('./containers/VerifyAttribute')));
