/* eslint react/forbid-prop-types: 0 */

import React, { Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import posed, { PoseGroup } from 'react-pose';

import ErrorBoundary from './components/ErrorBoundary';
// import RouteApplied from './components/RouteApplied';
import RouteAuthenticated from './components/RouteAuthenticated';
import RouteUnauthenticated from './components/RouteUnauthenticated';

import Loading from './containers/Loading';

import {
  AsyncForgotPassword,
  // AsyncHome,
  AsyncLogin,
  AsyncLogout,
  AsyncNotFound,
  AsyncRedirect,
  // AsyncRegister,
  AsyncRegisterRedirect,
  AsyncLawyerRegister,
  AsyncResetPassword,
  AsyncNewPassword,
  AsyncVerifyAttribute,
} from './RouteLoaders';

import './Routes.css';

const RouteContainer = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
});

const Routes = () => (
  <Route
    render={({ location }) => (
      <PoseGroup>
        <RouteContainer key={location.pathname} className="route-wrapper">
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <Switch location={location}>
                {/* <RouteAuthenticated path="/" exact component={AsyncHome} /> */}
                <RouteAuthenticated path="/" exact component={AsyncRedirect} />
                <RouteUnauthenticated path="/login" component={AsyncLogin} />
                <RouteUnauthenticated path="/register/:id" component={AsyncLawyerRegister} />
                <RouteUnauthenticated path="/register" component={AsyncRegisterRedirect} />
                <RouteUnauthenticated path="/reset_password" component={AsyncResetPassword} />
                <RouteUnauthenticated path="/new_password" component={AsyncNewPassword} />
                <RouteUnauthenticated path="/forgot_password" component={AsyncForgotPassword} />
                <RouteUnauthenticated path="/verify" component={AsyncVerifyAttribute} />
                <RouteUnauthenticated path="/adfs/:provider" component={AsyncRedirect} />
                <Route path="/logout" exact component={AsyncLogout} />
                <Route path="/not_found" exact component={AsyncNotFound} />
                <Redirect to="/not_found" />
              </Switch>
            </Suspense>
          </ErrorBoundary>
        </RouteContainer>
      </PoseGroup>
    )}
  />
);

export default Routes;
