/* eslint-disable react/jsx-filename-extension */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';

import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

import './index.css';
import Root from './Root';
import config from './config';
import { authConfig, apiConfig } from './amplifyConfig';
import * as serviceWorker from './serviceWorker';

const bugsnagClient = bugsnag({
  apiKey: 'aa93e6c180328864d05e36cec3a3d7cd',
  releaseStage: config.bugsnag.RELEASE_STAGE,
  appVersion: config.app.version,
});
bugsnagClient.use(bugsnagReact, React);
window.bugsnagClient = bugsnagClient;

const ErrorBoundary = bugsnagClient.getPlugin('react');

Auth.configure(authConfig);

if (apiConfig.endpoints.length > 0) {
  API.configure(apiConfig);
}

ReactDOM.render(
  <ErrorBoundary>
    <Root />
  </ErrorBoundary>,
  document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
