import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from '../../Routes';
import GA from '../../googleAnalytics';
import config from '../../config';
import { dayClass, transformOrigin, randomClassByHour } from '../../utils/bgStyle';
import './styles.css';

const App = () => {
  useEffect(() => {
    // add loaded class to body after 50ms for css background transition
    const timeout = setTimeout(() => {
      document.body.classList.add('loaded');
    }, 50);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <div className="App">
      <div className={`background ${randomClassByHour(config.app.bgClass, config.app.bgCount || 1) || dayClass()}`} style={{ transformOrigin }} />
      <BrowserRouter>
        {GA.init() && <GA.RouteTracker />}
        <Routes />
      </BrowserRouter>
    </div>
  );
};

export default App;
